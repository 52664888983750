<template>
    <div v-loading="isLoadingChart">
        <div>
            <div class="d-flex align-items-center align-content-center">
                <div class="d-flex align-items-center">
                    <select class="form-control form-control-sm mr-2"
                            style="width: 8rem;" v-model="numberOfMonth"
                            @change="getCountUserRechargeLevelByMonth">
                        <option value="6">Last 6 months</option>
                        <option value="8">Last 8 months</option>
                        <option value="10">Last 10 months</option>
                        <option value="12">Last 12 months</option>
                    </select>
                    <button class="btn btn-sm btn-primary" @click="getCountUserRechargeLevelByMonth">
                        <i class="fas fa-sync-alt mr-2"></i> Refresh
                    </button>
                </div>
            </div>
        </div>
        <highcharts class="mt-4 mr-1" :options="countUserRechargeLevelByMonth.options"></highcharts>
        <hr/>
    </div>
</template>
<script>
    import chartDataApi from '@/api/common/chartData';
    import { 
        areaSplineChart,
        colorSun, colorSat, colorNormal
    } from "@/constant/dashboardChartOptions";
    import { Chart } from 'highcharts-vue';
    var jsonToPivotjson = require("json-to-pivot-json");
    import moment from "moment";
    var numeral = require('numeral');
    export default {
        components: {
            highcharts: Chart
        },
        data() {
            return {
                isLoadingChart: false,
                radioTargetView: 'all',
                numberOfMonth: 6,
                levelRechargeMap: { "1" : 100, "2" : 200, "3": 500, "4": 1000, "5": 3000 },
                levelRechargeMapLegend: { "1" : `Rechaged amount > $100`, "2" : `Rechaged amount > $200`, "3": `Rechaged amount > $500`, "4": `Rechaged amount > $1000 & < $3000`, "5": `Rechaged amount >= $3000` },
                countUserRechargeLevelByMonth: {
                    options: {
                        chart: {
                            type: 'column'
                        },
                        title: {
		                    text: '',
	                    },
                        xAxis: {
                            categories: [],
                            crosshair: true
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: 'Number of users'
                            }
                        },
                        tooltip: {
                            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                '<td style="padding:0"><b>{point.y:.1f} user</b></td></tr>',
                            footerFormat: '</table>',
                            shared: true,
                            useHTML: true
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
				                    enabled: true
			                    },
                                pointPadding: 0.2,
                                borderWidth: 0
                            }
                        },
                        series: []
                    },
                    tableData: []
                }
            };
        },
        mounted() {
            this.getCountUserRechargeLevelByMonth();
        },
        methods: {
            getCountUserRechargeLevelByMonth() {
                this.isLoadingChart = true;
                chartDataApi.getCountUserRechargeLevelByMonth(this.numberOfMonth).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        var options = {
                            row: "MonthExport",
                            column: "RechargeLevel",
                            value: "CountUserRecharge"
                        };
                        let output = jsonToPivotjson(response.data.data, options);
                        this.countUserRechargeLevelByMonth.options.xAxis.categories = output.map(x => x.MonthExport);
                        this.countUserRechargeLevelByMonth.options.series = [];
                        for (var i = 1; i <= Object.keys(this.levelRechargeMap).length; i++) {
                            this.countUserRechargeLevelByMonth.options.series.push({
                                name: this.levelRechargeMapLegend[i.toString()],
                                data: output.map(x => {
                                    return {
                                        y: x[i.toString()],
                                    }
                                })
                            });
                        }
                        this.isLoadingChart = false;
                    }
                    else {
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            console.error(response.data.message || this.$lang.common.error);
                        }
                        this.isLoadingChart = false;
                    }
                }).catch(error => {
                    console.error(error);
                    this.isLoadingChart = false;
                });
            },
        }
    }
</script>